<template>
  <!-- 租车规则配置 -->
  <div class="CarRentalConfig">
    <div class="rule-tabs">
      <div class="rule-tabs-title">
        <div
          v-for="(item, index) in tabsList"
          :key="item.text"
          :class="item.class"
          @click="tabClick(index)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="rule-tabs-content">
        <el-button
          v-if="operAtionIndex == 1"
          class="tabs-add"
          size="small"
          sort="primary"
          @click="addFun"
          >添加</el-button
        >
        <Table
          :table-data="tableData"
          :title-name="titleName"
          :col-class-type="true"
          :operation="true"
          :operation-width="operAtionIndex == 1 ? '110' : '70'"
        >
          <template slot-scope="scope">
            <el-button
              v-if="
                operAtionIndex == 1
                  ? scope.scopeRow.DataLength == scope.scopeRow.$index + 1
                    ? true
                    : false
                  : true
              "
              type="text"
              size="small"
              sort="primary"
              @click="editFun(scope.scopeRow)"
              >编辑</el-button
            >
            <el-button
              v-if="
                operAtionIndex == 1
                  ? scope.scopeRow.DataLength == scope.scopeRow.$index + 1
                    ? true
                    : false
                  : false
              "
              type="text"
              size="small"
              sort="primary"
              @click="deleteFun(scope.scopeRow)"
              >删除</el-button
            >
          </template>
        </Table>
      </div>
    </div>
    <OperAtion
      v-if="operAtionShow"
      :header-text="headerText"
      :popup-num="popupNum"
      :oper-ation-data="operAtionData"
      @confirmFun="confirmFun"
      @closePopupFun="closePopupFun"
    />
    <charterPopup
      v-if="show"
      :header-text="headerText"
      @confirmFun="confirmFormFun"
      @closePopupFun="clearForm"
    >
      <el-form
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="140px"
        label-position="right"
        class="demo-form dialog-form"
      >
        <el-form-item label="值：" prop="formValue">
          <el-input
            v-if="type == 'number'"
            v-model="Form.formValue"
            onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            type="number"
          ></el-input>
          <el-input
            v-else-if="type == 'string'"
            v-model="Form.formValue"
          ></el-input>
          <el-select v-else v-model="Form.formValue">
            <el-option
              v-for="(i, ind) in list"
              :key="ind"
              :label="i.desc"
              :value="i.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </charterPopup>
  </div>
</template>

<script>
import {
  rentBookAPI,
  rentUnsubAPI,
  rentUseAPI,
  delRentUnsubAPI,
  addRentUnsubAPI,
  updateRentUnsubAPI,
  updateRentUseAPI,
  updateRentBookAPI,
} from "@/api/lib/api.js";
export default {
  props: {
    channelNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tabsList: [
        { class: "tabs tabs-active", text: "预订" },
        { class: "tabs", text: "退订" },
        { class: "tabs", text: "行车" },
      ],
      operAtionIndex: 0,
      operAtionData: {},
      titleName: [],
      titleName1: [
        {
          title: "规则代码",
          props: "regulationCode",
        },
        {
          title: "规则名",
          props: "regulationName",
        },
        {
          title: "值",
          SpecialJudgment: (res) => {
            if (
              res.regulationCode == "isPayDeposit" ||
              res.regulationCode == "isSubmitLicence"
            )
              return res.regulationValue ? "是" : "否";
            else return res.regulationValue;
          },
        },
        {
          title: "单位",
          props: "unitType",
          SpecialJudgment: (res) => {
            return res === 0
              ? "天"
              : res === 1
              ? "分钟"
              : res === 2
              ? "人"
              : res === 3
              ? "小时"
              : res === 4
              ? "个"
              : res === 7
              ? "是/否"
              : "";
          },
        },
      ],
      titleName2: [
        {
          title: "发车前时间区间起（含）",
          props: "departureTimeStart",
        },
        {
          title: "发车前时间区间止（不含）",
          props: "departureTimeEnd",
        },
        {
          title: "收取手续费比率",
          props: "chargeRate",
          SpecialJudgment: (res) => {
            return res + "%";
          },
        },
      ],
      titleName3: [
        {
          title: "规则代码",
          props: "regulationCode",
        },
        {
          title: "规则说明",
          props: "regulationName",
        },
        {
          title: "值",
          props: "regulationValue",
        },
        {
          title: "单位",
          props: "unitType",
          SpecialJudgment: (res) => {
            return res === 0 ? "公里" : res === 1 ? "座" : "-";
          },
        },
      ],
      tableData: [],
      headerText: "",
      popupNum: null,
      operAtionShow: false,
      show: false,
      Form: {
        id: "",
        formValue: null,
      },
      type: "",
      rules: {
        formValue: [{ required: true, message: "值不能为空", trigger: "blur" }],
      },
      list: [
        {
          code: 0,
          desc: "否",
        },
        {
          code: 1,
          desc: "是",
        },
      ],
    };
  },
  methods: {
    tabClick(index, flag) {
      this.operAtionIndex = index;
      this.tabsList.map((item) => {
        item.class = "tabs";
      });
      this.tabsList[index].class = "tabs tabs-active";
      switch (index) {
        case 0:
          !flag && this.renderData1();
          break;
        case 1:
          this.renderData2();
          break;
        case 2:
          this.renderData3();
          break;
      }
    },
    renderData1() {
      this.titleName = this.titleName1;
      rentBookAPI({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    renderData2() {
      this.titleName = this.titleName2;
      rentUnsubAPI({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    renderData3() {
      this.titleName = this.titleName3;
      rentUseAPI({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    addFun() {
      if (this.tableData.length == 0) {
        this.operAtionData = {
          departureTimeStart: 0,
        };
      } else {
        this.operAtionData = {
          departureTimeStart:
            this.tableData[this.tableData.length - 1].departureTimeEnd,
        };
      }
      this.headerText = "新增";
      this.popupNum = 6;
      this.operAtionShow = true;
    },
    editFun(val) {
      this.headerText = "编辑";
      if (this.operAtionIndex == 1) {
        this.operAtionData = val;
        this.operAtionShow = true;
        this.popupNum = 6;
      } else if (this.operAtionIndex == 0 || this.operAtionIndex == 2) {
        if (this.operAtionIndex == 0) {
          if (
            val.regulationCode == "isPayDeposit" ||
            val.regulationCode == "isSubmitLicence"
          )
            this.type = "select";
          else this.type = "number";
        } else {
          this.type = "string";
        }
        this.show = true;
        this.Form.id = val.id;
        this.Form.formValue = val.regulationValue;
      }
    },
    deleteFun(val) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: "确定取消？",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        delRentUnsubAPI({ id: val.id }).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("删除成功");
            this.renderData2();
          }
        });
      });
    },
    confirmFun(val) {
      if (
        val.departureTimeStart === "" ||
        val.departureTimeStart == undefined ||
        val.departureTimeEnd === "" ||
        val.departureTimeEnd == undefined ||
        val.chargeRate === "" ||
        val.chargeRate == undefined
      ) {
        this.$message.error("请填写必填项");
      } else {
        if (this.headerText == "新增") {
          addRentUnsubAPI({
            departureTimeStart: val.departureTimeStart,
            departureTimeEnd: val.departureTimeEnd,
            chargeRate: val.chargeRate,
            channelNo: this.channelNo,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              this.operAtionShow = false;
              this.$message.success("添加成功");
              this.renderData2();
            }
          });
        } else {
          updateRentUnsubAPI({
            id: val.id,
            departureTimeStart: val.departureTimeStart,
            departureTimeEnd: val.departureTimeEnd,
            chargeRate: val.chargeRate,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              this.operAtionShow = false;
              this.$message.success("修改成功");
              this.renderData2();
            }
          });
        }
      }
    },
    closePopupFun() {
      this.operAtionShow = false;
    },
    confirmFormFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          let obj = this.deepClone(this.Form);
          if (this.operAtionIndex == 0) {
            updateRentBookAPI({
              id: obj.id,
              regulationValue: obj.formValue,
            }).then((res) => {
              if (res.code == "SUCCESS") {
                this.show = false;
                this.$message.success("修改成功");
                this.renderData1();
              }
            });
          } else if (this.operAtionIndex) {
            updateRentUseAPI({
              id: obj.id,
              regulationValue: obj.formValue,
            }).then((res) => {
              if (res.code == "SUCCESS") {
                this.show = false;
                this.$message.success("修改成功");
                this.renderData3();
              }
            });
          }
        }
      });
    },
    clearForm() {
      this.show = false;
    },
  },
  watch: {
    channelNo() {
      this.renderData1();
    },
  },
  mounted() {
    this.renderData1();
  },
};
</script>

<style scoped lang="scss">
.CarRentalConfig {
  height: 100%;
  .rule-tabs {
    width: 100%;
    border: 1px solid #dcdfe6;
    .rule-tabs-title {
      height: 40px;
      background: #f5f7fa;
      border-bottom: 1px solid #dcdfe6;
      .tabs {
        display: inline-block;
        height: 100%;
        padding: 0 20px;
        cursor: pointer;
        color: #909399;
        font-size: 14px;
        line-height: 40px;
        margin-left: -1px;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
      }
      .tabs-active {
        border-bottom: 1px solid #ffffff;
        color: #409eff;
        background-color: #fff;
        border-right: 1px solid #dcdfe6;
        border-left: 1px solid #dcdfe6;
      }
    }
    .rule-tabs-content {
      padding: 15px;
      background: #ffffff;
      .tabs-add {
        background: #336fee;
        color: #ffffff;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
